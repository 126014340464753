<template>
  <section class="coovally" :style="{ marginTop: marginTop }">
    <!-- <div class="activity" v-show="isacticity" >
      <img src="../../../assets/zh/images/shouye_huodong@2x.png" @click="RouteActicity()" alt="" />
      <div class="close" @click="close()"><span style="color:white;" class="iconfont iconToast_closed"  ></span></div>
    </div> -->
    <section class="banner">
      <figure class="buttons">
        <div class="button">
          <el-button
            class="unchecked"
            style="width: 136px"
            @click="RoutePush('/CooVallyHome')"
            >{{ $t("PC.Product.CooVallyButton.deaa52") }}</el-button
          >
          <el-button class="checked" style="width: 136px">{{
            $t("PC.Product.CooVallyButton.2446d2")
          }}</el-button>
          <el-button class="unchecked" @click="RoutePush('/AIShopNew')">{{
            $t("PC.Product.CooVallyButton.fad28a")
          }}</el-button>
          <!-- <el-button class="unchecked" @click="RoutePush('/ApplyTrial')">{{
            $t("PC.Product.CooVallyButton.ed4a49")
          }}</el-button> -->
        </div>
      </figure>

      <figure class="firstItem">
        <div class="title">
          <span>{{ $t("PC.Product.CooVallyHome.f5c726") }}</span>
        </div>
        <div class="text">
          <p>
            {{ $t("PC.Product.CooVallyHome.bc4bfe") }}
          </p>
        </div>
      </figure>

      <div class="mainImg">
        <img :src="$t('locale') == 'zh' ? suanfas : suanfas_en" alt="" />
      </div>

      <div class="apply">
        <el-button class="applyButton" @click="GoCooVally()">{{
          $t("PC.Product.CooVallyButton.ed4a49")
        }}</el-button>
      </div>

      <figure class="support">
        <div class="type">
          <div class="img">
            <img src="../../../assets/zh/images/icon1@2x.png" alt="" />
          </div>

          <div class="title">
            <span>{{ $t("PC.Product.CooVallyHome.sptype") }}</span>
          </div>
          <div class="text">
            <p>
              {{ $t("PC.Product.CooVallyHome.typetx") }}
            </p>
          </div>
        </div>

        <div class="function">
          <div class="img">
            <img src="../../../assets/zh/images/icon2@2x.png" alt="" />
          </div>

          <div class="title">
            <span>{{ $t("PC.Product.CooVallyHome.spfunc") }}</span>
          </div>
          <div class="text">
            <p>
              {{ $t("PC.Product.CooVallyHome.functx") }}
            </p>
          </div>
        </div>
      </figure>

      <figure class="model">
        <div class="title">
          <span>{{ $t("PC.Product.CooVallyHome.usemod") }}</span>
        </div>
        <div class="text">
          <p>
            {{ $t("PC.Product.CooVallyHome.seacmd") }}
          </p>
        </div>
        <div class="allmodel">
          <div class="caseContent" v-for="all in modellist" :key="all.title">
            <div class="title">
              <p>{{ all.title }}</p>
            </div>
            <div class="list">
              <div class="onetext" v-for="model in all.model" :key="model">
                <span class="icon"></span>
                <span class="msg">{{ model }}</span>
              </div>
            </div>
          </div>
        </div>
      </figure>

      <figure class="moremodel">
        <div class="img">
          <img src="../../../assets/zh/images/maobi_img@2x.png" alt="" />
        </div>
        <div v-show="$t('locale') == 'zh'" class="text">
          <p>{{ $t("PC.Product.CooVallyHome.flowal") }}</p>
        </div>
        <div v-show="$t('locale') == 'en'" class="text_en">
          <p>{{ $t("PC.Product.CooVallyHome.flowal") }}</p>
          <p style="margin-top: 8px">
            {{ $t("PC.Product.CooVallyHome.flowa2") }}
          </p>
        </div>
      </figure>

      <FooterC></FooterC>
    </section>
  </section>
</template>


<script>
import FooterC from "../components/FooterC.vue";
export default {
  name: "Home",
  components: {
    FooterC,
  },

  data() {
    return {
      date: "",
      marginTop: "",
      isacticity: true,
      ContactUsVisible: false,
      suanfas: require("../../../assets/zh/images/home_img1@2x.png"),
      suanfas_en: require("../../../assets/zh/images/home_img1_en@2x.png"),
      modellist: [
        {
          title: this.$t("PC.Product.CooVallyHome.mode01"),
          model: [
            "Faster R-CNN ",
            "RetinaNet",
            "SSD",
            "Cascade R-CNN",
            "FCOS ",
            "YoloV3 ",
            "FSAF",
            "CornerNet",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode02"),
          model: [
            "Mask R-CNN ",
            "Cascade Mask R-CNN",
            "Mask Scoring R-CNN",
            "SOLO",
            "SCNet",
            "PointRend",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode03"),
          model: ["ResNet18 ", "ResNet34", "ResNet50", "ResNet101", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode04"),
          model: ["PSPNet", "FCN", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode05"),
          model: [
            this.$t("PC.Product.CooVallyHome.smoot1"),
            this.$t("PC.Product.CooVallyHome.smoot2"),
            this.$t("PC.Product.CooVallyHome.smoot3"),
            this.$t("PC.Product.CooVallyHome.smoot4"),
            this.$t("PC.Product.CooVallyHome.smoot5"),
            this.$t("PC.Product.CooVallyHome.smoot6"),
            this.$t("PC.Product.CooVallyHome.smoot7"),
            this.$t("PC.Product.CooVallyHome.smoot8"),

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode06"),
          model: [
            this.$t("PC.Product.CooVallyHome.enhan1"),
            this.$t("PC.Product.CooVallyHome.enhan2"),
            this.$t("PC.Product.CooVallyHome.enhan3"),
            this.$t("PC.Product.CooVallyHome.enhan4"),
            this.$t("PC.Product.CooVallyHome.enhan5"),
            this.$t("PC.Product.CooVallyHome.enhan6"),
            this.$t("PC.Product.CooVallyHome.enhan7"),

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode07"),
          model: [
            this.$t("PC.Product.CooVallyHome.datas1"),
            this.$t("PC.Product.CooVallyHome.datas2"),
            this.$t("PC.Product.CooVallyHome.datas3"),
            this.$t("PC.Product.CooVallyHome.datas4"),
            this.$t("PC.Product.CooVallyHome.datas5"),
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode08"),
          model: ["HSV", "YUV", "GARY", "RGB", "LUV", "HLS", "LAB", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode09"),
          model: [
            "FastICA",
            "FeatureAgglomeration",
            "StandardScaler",
            "ZeroCount",
            "Normalizer",
            "MaxAbsScaler",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode10"),
          model: [
            "VarianceThreshold",
            "SelectFromModel",
            "RFE",
            "SelectPercentile",
            "SelectFwe",

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode11"),
          model: [
            "Perceptron",
            "LogisticRegression",
            "LGBMClassifie",
            "AdaBoostClassifier",
            "GaussianNB",
            "BernoulliNB",
            "LinearSVC",
            "NearestCentroid",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode12"),
          model: [
            "AdaBoostRegressor",
            "RANSACRegressor",
            "LassoCV",
            "LinearSVR",
            "ExtraTreesRegressor",
            "ElasticNetCV",
            "BaggingRegressor",
            "DecisionTreeRegressor",

            "···",
          ],
        },
      ],
    };
  },
  created() {
    this.marginTop = "70px";
  },
  mounted() {
    this.date = new Date().getFullYear() + "";
  },
  methods: {
    ContactUs() {
      this.ContactUsVisible = true;
    },
    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href);
    },
    newOpen(url) {
      window.open(url);
    },
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },

    GoCooVally() {
      let url = Global.COOVALLY_URL;
      window.open(url, "_blank");
    },
    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    close() {
      this.marginTop = "70px";
      this.isacticity = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.coovally {
  // margin-top: 180px;
  // margin-top: 9.5%;
  background: linear-gradient(
    180deg,
    #eef2fe 0%,
    #f8f9ff 58%,
    rgba(238, 242, 254, 0) 100%
  );
  word-break: break-word !important;
  //首屏
  .banner {
    position: relative;
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    // margin-top: -16px;
    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        justify-content: space-between;
        width: 558px;
        padding-top: 48px;
        .checked {
          width: 136px;
          height: 60px;
          background-color: #4568ee;
          color: #fafcff;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .checked:hover {
          width: 136px;
          height: 60px;
          background: #4e76f7;
          // border-radius: 26px;
          opacity: 0.8;
        }

        .unchecked {
          width: 180px;
          height: 60px;
          background-color: #4568ee1a;
          color: #4568ee;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .unchecked:hover {
          width: 180px;
          height: 60px;
          background: #4568ee;
          color: #fafcff;
          // border-radius: 26px;
          // opacity: 0.8;
        }
      }
    }

    .firstItem {
      // height: 20%;
      margin-top: 54px;
      // background-color: green;
      position: relative;
      text-align: center;

      .title {
        span {
          // width: 1005px;
          height: 64px;
          font-size: 47px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d3242;
          line-height: 64px;
        }
      }
      .text {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 64px;
        p:nth-of-type(1) {
          // width: 840px;
          margin-top: 19px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2d3242;
          line-height: 24px;
        }
      }
    }
    .mainImg {
      position: relative;
      margin: 0 auto;
      width: 1080px;
      height: 632px;
      margin-top: -50px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .apply {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 56px;
      // margin-bottom: 100px;
      .applyButton {
        width: 186px;
        height: 55px;
        background: #4568ee;
        color: #fafcff;
        border-radius: 4px;
        padding: 0 0 0 0;
        font-size: 20px;
      }
      .applyButton:hover {
        width: 186px;
        height: 55px;
        background: #4e76f7;
        opacity: 0.8;
      }
    }

    .support {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 87px;
      // background-color: green;
      .type {
        width: 576px;
        height: 258px;
        // background: linear-gradient(180deg, #F9FFFF 0%, #F0F4FE 100%, #F0F4FE 100%);
        background-image: url("../../../assets/zh/images/bg1@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px;
        border: 1px solid rgba(151, 151, 151, 0);
        margin-right: 12px;
      }

      .function {
        width: 576px;
        height: 258px;
        background-image: url("../../../assets/zh/images/bg1@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px;
        border: 1px solid rgba(151, 151, 151, 0);
        margin-left: 12px;
      }
      .img {
        position: relative;
        top: 29px;
        left: 28px;
        img {
          width: 85px;
          height: 86px;
        }
      }
      .title {
        position: relative;
        top: 36px;
        left: 28px;
        // width: 132px;
        height: 36px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
      .text {
        position: relative;
        top: 58px;
        left: 28px;
        width: 520px;
        height: 34px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        line-height: 34px;
      }
    }

    .model {
      width: 100%;
      display: flex;

      flex-direction: column;
      align-items: center;
      margin-top: 80px;

      .title {
        // width:500px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f232e;
        line-height: 50px;
      }
      .text {
        // width: 400px;
        height: 34px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        line-height: 34px;
        margin-top: 13px;
        margin-bottom: 32px;
      }
      .allmodel {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .caseContent {
          width: 588px;
          margin-bottom: 24px;
          background: linear-gradient(
            180deg,
            #f9ffff 0%,
            #f0f4fe 100%,
            #f0f4fe 100%
          );
          border: 1px solid rgba(151, 151, 151, 0);
          .title {
            position: relative;
            width: 500px;
            top: 28px;
            left: 28px;
            height: 36px;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 36px;
          }
          .list {
            position: relative;
            top: 28px;
            left: 28px;
            width: 530px;
            padding-bottom: 28px;
            display: flex;
            flex-wrap: wrap;
            .onetext {
              // background-color: red;
              height: 34px;
              margin-bottom: 16px;

              display: flex;
              align-items: center;
              .icon {
                width: 8px;
                height: 8px;
                background: linear-gradient(180deg, #4ca9ff 0%, #3758ee 100%);
                box-shadow: 0px 2px 7px 0px rgba(104, 171, 247, 0.74);
                opacity: 0.8;
                border-radius: 4px;
              }
              .msg {
                margin-left: 8px;
                margin-right: 24px;
                height: 34px;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #636c78;
                line-height: 34px;
              }
            }
          }
        }
      }
    }
    .moremodel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 65px;
      .img {
        position: relative;
        width: 1000px;
        height: 50px;
        top: 0;
        left: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        position: relative;
        top: -30px;
        width: 800px;
        height: 60px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        line-height: 34px;
      }
      .text_en {
        position: relative;
        top: -45px;
        width: 800px;
        height: 60px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        line-height: 60px;
      }
    }
  }

  .activity {
    position: fixed;
    top: 70px;
    left: 0px;
    z-index: 9;
    img {
      width: 100%;
    }
    img:hover {
      cursor: pointer;
    }

    .close {
      z-index: 99;
      position: fixed;
      left: 98%;
      top: 12vh;
      // background: red;
      widows: 10px;
      height: 20px;
    }
  }
}
</style>
